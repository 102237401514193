*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  background: #deac79;
}
body, #root{
  width: 100%;
  height: 100%;
}
